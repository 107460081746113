@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";
@import 'baguettebox.js/dist/baguetteBox.min.css';

:root, [data-bs-theme=light] {
    --bs-blue: #1173B0;
    --bs-body-color: #A7AAB4;
    --bs-body-bg: #FFFFFF;
    --btn-primary: #0069E0;
    --bg-card-secondary: #F8F8F9;
    --bg-card-body: #fff;
    --btn-text-color: #fff;
}

:root, [data-bs-theme=dark] {
    --bs-blue: #00FFE2;
    --bs-body-color: #fff;
    --bs-body-bg: #121E40;
    --btn-primary: #00FFE2;
    --bg-card-secondary: #0F1834;
    --bg-card-body: #293352;
    --btn-text-color: #070D19;
}

:root {
    --bg-previous-post-card:#222D4D;

    /* Light mode variables */
    --light-title: #1F2D57;
    --light-primary: #0069E0;
    --light-secondary: #F8F8F9;
  
    --light-link-color: #0069E0;
    --light-link-hover-color: #3B95FF;
  
    /* Dark mode variables */
    --dark-primary: #00FFE2;
    --dark-secondary: #0F1834;
    
    --dark-link-color: #00FFE2;  
    --dark-link-text-color: #070D19;  
    --dark-link-hover-color: #3B95FF;
    --dark-link-bg-color: #18254B;
    --dark-comments-bg-color: #293352;
    --dark-bg-category-bar: #0C152C;
}

@include color-mode(light){
    a{
        color: var(--light-link-color) !important;
    }
    a:hover{
        color: var(--light-link-hover-color) !important;
    }

    h1, h2, h3, h4, h5, h6, .title-color {
        color: var(--light-title);
    }

    .category-nav-link {
        color: #fff !important;
    }
    .nav-link {
        color: var(--light-link-color);
    }
    .nav-link:hover {
        color: var(--light-link-hover-color);
    }
    .navbar-nav .nav-link:hover {
        color: var(--light-link-hover-color) !important;
    }
    .navbar-nav .nav-link::before, .navbar-nav .category-nav-link:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--light-link-hover-color);
        transition: width 0.3s ease;
    }

    .nav-underline .nav-link.active, .nav-underline .show > .nav-link {
        color: #fff !important;
    }
    
    .text-primary {
        color: var(--light-primary) !important;
    }

    .text-secondary {
        color: var(--light-secondary);
    }

    .bg-card {
        background-color: var(--light-secondary);
    }

    .icon-donate-color {
        fill: var(--light-link-color);
    }
    
    .btn-primary, .btn-success {
        color: #fff !important;
        background-color: var(--light-link-color);
        border-color: var(--light-link-color);
    }
    .btn-primary:hover, .btn-success:hover {
        color: #fff !important;
        background-color: var(--light-link-hover-color);
        border-color: var(--light-link-hover-color);
    }
    
    .btn-outline-primary {
        color: var(--light-link-color);
        border-color: var(--light-link-color);
    }
    .btn-outline-primary:hover {
        color: #fff !important;
        border-color: var(--light-link-hover-color);
        background-color: var(--light-link-hover-color);
    }
    .btn-outline-primary:hover > svg {
        fill: #fff;
    }

    .btn:focus-visible {
        color: #fff !important;
        border-color: var(--light-link-hover-color);
        background-color: var(--light-link-hover-color);
    }

    .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active {
        color: #fff !important;
        border-color: var(--light-link-hover-color);
        background-color: var(--light-link-hover-color);
    }

    .btn-outline-secondary {
        color: #fff;
        border-color: #fff;
    }

    .btn-outline-secondary:hover, .btn-outline-secondary:active, .btn-outline-secondary:focus-visible, .btn.show {
        background-color: var(--dark-bg-category-bar) !important;
        border-color: var(--dark-bg-category-bar) !important;
    }

    /**
     * Custom Classes
    */

    .bg-products-card {
        background-color: var( --light-secondary );
    }
    .bg-products-card:hover {
        background-color: var( --light-link-hover-color );
    }

    .bg-navbar-primary {
        background-color: var( --bs-body-bg );
    }
    
    .bg-submenu {
        background-color: var(--light-secondary);
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: var( --light-secondary ) !important;
    }
    
    .bg-secondary, .bg-other-books, .bg-his-card, .bg-project-card {
        background-color: var( --light-secondary ) !important;
    }
    .bg-his-card:hover {
        color: #fff !important;
        background-color: var(--light-link-hover-color) !important;
    }
    .bg-his-card:hover > span {
        color: #fff !important;
    }
    
    .bg-other-books:hover {
        background-color: var( --light-link-hover-color ) !important;
        p {
            color: #fff !important;
        }
    }

    .bg-comments {
        color: var(--light-title) !important;
        background-color: var(--light-secondary);
    }
    .bg-archives {
        background-color: var(--light-secondary);
    }

    .bg-archives:hover {
        background-color: var(--light-link-hover-color);
    }
    .bg-archives:hover > a {
        color: #fff !important;
    }

    .bg-categories-bar {
        background-color: var(--light-primary);
    }


    .bg-border-primary {
        border-color: var( --light-primary ) !important;
    }
    .bg-border-primary:hover {
        border-color: var(--light-link-hover-color) !important;
    }
    .social-media-circle:hover {
        background-color: var(--light-link-hover-color);
    }
    .social-media-circle:hover > span {
        color: #fff;
    }

    #categories > li > a {
        color: #fff !important;
    }

    #searchForm > .input-group-btn > button {
        color: #fff !important;
        border-color: #fff !important;
    }

    .bg-category-aside {
        background-color: var(--light-secondary);
    }

    #q::placeholder{
        color: #fff !important;
    }
}

@include color-mode(dark){
    a{
        color: var(--dark-link-color);
    }
    a:hover{
        color: #fff;
    }

    .nav-link, .category-nav-link {
        color: var(--dark-link-color) !important;
    }
    .nav-link:hover, .category-nav-link:hover {
        color: var(--dark-link-hover-color);
    }
      
    .nav-link:focus, .nav-link:hover, .category-nav-link:hover {
        color: var(--dark-link-color) !important;
    }

    .navbar-nav .nav-link:hover, .navbar-nav .category-nav-link:hover{
        color: #fff !important;
    }
    .navbar-nav .nav-link::before, .navbar-nav .category-nav-link:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--dark-link-hover-color);
        transition: width 0.3s ease;
    }

    .bg-navbar-primary {
        background-color: var( --bs-body-bg );
    }
    
    .text-primary {
        color: var(--dark-primary) !important;
    }

    .text-secondary {
        color: var(--dark-secondary);
    }

    .bg-card {
        background-color: var(--dark-secondary);
    }

    .icon-donate-color {
        fill: var(--dark-link-color);
    }
    
    .btn-primary, .btn-success {
        color: var(--dark-link-text-color);
        background-color: var(--dark-link-color);
        border-color: var(--dark-link-color);
    }

    .btn-primary:hover, 
    .btn-primary:focus-visible, 
    .btn-success:hover, 
    .btn-success:focus-visible 
    {
        color: #fff;
        background-color: var(--dark-link-hover-color);
        border-color: var(--dark-link-hover-color);
    }
    
    .btn-outline-primary {
        color: var(--dark-link-color);
        border-color: var(--dark-link-color);
    }
    .btn-outline-primary:hover, .btn-outline-primary:focus-visible {
        color: #fff;
        border-color: var(--dark-link-hover-color);
        background-color: var(--dark-link-hover-color);
    }
    .btn-outline-primary:hover > svg {
        fill: #fff !important;
    }

    .btn-outline-secondary:hover, .btn-outline-secondary:active, .btn-outline-secondary:focus-visible {
        background-color: transparent !important;
        border-color: #6f7881 !important;
    }

    .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active {
        color: #fff;
        border-color: var(--dark-link-hover-color);
        background-color: var(--dark-link-hover-color);
    }

    /*
     * Custom classes
    */

    .post {
        h1 > a, h2 > a, h3 > a {
            color: #fff;
        }
    }

    .blog-badge > a {
        color: #A7AAB4;
    }

    .bg-products-card {
        background-color: #fff;
    }
    .bg-products-card:hover {
        background-color: var( --light-title );
    }

    .bg-submenu {
        background-color: var(--dark-link-bg-color);
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: var( --dark-secondary ) !important;
    }
    
    .bg-secondary, .bg-other-books, .bg-his-card {
        background-color: var( --dark-secondary ) !important;
        color: #fff;
    }
    .bg-his-card:hover {
        background-color: var(--light-link-hover-color) !important;
    }
    
    .bg-other-books:hover {
        background-color: var( --dark-primary ) !important;
        color: var(--dark-secondary);
    }

    .bg-comments, .bg-archives, .bg-category-aside {
        background-color: var(--dark-comments-bg-color);
    }

    .bg-archives:hover {
        background-color: var(--dark-link-hover-color);
    }
    .bg-archives:hover > a {
        color: #fff !important;
    }

    .bg-categories-bar {
        background-color: var( --dark-bg-category-bar );
    }
    #categories > li > a {
        color: #fff !important;
    }

    .bg-border-primary {
        border-color: var( --dark-primary ) !important;
    }
    .bg-border-primary:hover {
        border-color: var(--dark-link-hover-color) !important;
    }

    .social-media-circle:hover {
        background-color: var(--dark-link-hover-color);
    }
    .social-media-circle:hover > span {
        color: #fff;
    }

    .recentEntries:hover > a {
        color: var( --light-link-hover-color ) !important;
    }
    .bg-project-card {
        background-color: var( --dark-secondary );
    }
    .bg-project-card:hover {
        background-color: var( --light-title );
    }
}

/* ****************************
 * ####### Panel Styles #######
 * ****************************
 */
.searchResults .searchResultsCount {
    margin-bottom: 20px;
}

.well-sm {
    border-radius: 3px;
    padding: 9px;
}
.well {
    background-color: var(--bg-card-secondary);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    margin-bottom: 20px;
    min-height: 20px;
    padding: 19px;
    font-size: 20px;
}

.panel-default {
    border-color: #ddd;
}

.panel {
    font-size: 20px;
    background-color: var(--bg-card-body);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    margin-bottom: 20px;
}

.panel-default>.panel-heading {
    background-color: var(--bg-card-secondary);
    border-color: #ddd;
    color: #333;
    font-size: 20px;
}

.panel-heading {
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
}

.panel-title {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
}

.panel-heading>.dropdown .dropdown-toggle, .panel-title {
    color: inherit;
}

.panel-body {
    white-space: normal;
    word-break: break-all;
    background: var(--bg-card-body);
    border: 0 !important;
    margin: 0 15px 15px;
}

.panel-body {
    padding: 15px;
}

.searchResults cite {
    font-weight: bold;
    color: var(--bs-body-color);
    padding: 5px;
}

.label {
    font-weight: 600px;
    margin-bottom: 10px;
}
.label-primary {
    background-color: var(--btn-primary);
}

.label {
    border-radius: .25em;
    color: var(--btn-text-color);
    display: inline;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    padding: .2em .6em .3em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.panel-footer {
    background-color: var(--bg-card-secondary);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 1px solid #ddd;
    padding: 10px 15px;
}

.pagingrow {
    text-align: center;
}

.wrap-content {
    white-space: normal;
    word-break: break-all;
}